@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Open Sans", sans-serif;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

a.no-underline {
  text-decoration: none;
}

.no-border {
  border: none !important;
}

.text-underline {
  text-decoration: underline;
}
.text-bold {
  font-weight: bold;
}

.page-wrapper {
  max-width: 1060px;
  margin: 0 auto;
  box-sizing: border-box;
}

.page-wrapper-padded {
  padding: 0 20px;
}
/* designs show this, didnt want to break things till we re-adjust */
.page-wrapper.temp-wrapper-fix {
  max-width: 767px;
  box-sizing: content-box;
}

.no-width {
  width: 0 !important;
}

@media only screen and (max-width: 978px) {
  .page-wrapper {
    padding: 0;
  }
}

[data-disable-tab="true"] input, [data-disable-tab="true"] textarea, [data-disable-tab="true"] select, [data-disable-tab="true"] button, [data-disable-tab="true"] [tabindex] {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
}
